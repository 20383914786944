(function ($, Drupal) {
  var videoBehavior = {
    setup: function ($modules, context) {
      var self = this;

      $modules.each(function () {
        init($(this));
      });

      function init($module) {
        var $play = $('.js-play', $module);
        var $reveal = $('.js-content-block-video-reveal', $module);
        var $iframeVideo = $('.js-iframevideo', $module);
        var $videoIframe = $('.js-iframevideo iframe', $module);
        var provider = $module.data('video-provider');
        var isYoutube = provider === 'youtube';
        var isYouku = provider === 'youku';
        var isHTML5 = provider === 'html5';
        var isIframe = provider === 'iframe';

        $play.off('click.play').on('click.play', function (event) {
          event.preventDefault();
          // Pass options with overlay / where to insert video content if not overlay
          var opts = {
            context: context,
            videoProvider: provider,
            openOverlay: $play.hasClass('js-play--overlay'),
            $content: $reveal
          };

          $(document).trigger('videos.pause');
          // Triggered once video is loaded via JS and ready to show
          $reveal.off('video.loaded').on('video.loaded', function () {
            // When this video starts playing, let the content block handle the active state
            if ($(this).closest('.js-content-block').length) {
              $(this).closest('.js-content-block').trigger('video.playing');
            }
            if (!opts.openOverlay) {
              $module.addClass('active');
              setTimeout(function () {
                $module.addClass('complete');
              }, 300);
            }
          });

          if (!!isYoutube) {
            opts.youTubeId = $module.data('youtube-id');
          } else if (!!isHTML5) {
            opts.content = $module.find('video').wrap('<div />').parent().html();
          } else if (!!isYouku) {
            opts.youkuId = $module.data('youku-id');
          } else if (!!isIframe) {
            $module.addClass('active');
            $videoIframe.attr('src', $iframeVideo.data('iframe-src'));
          }
          self.open(opts);

          $module.off('video.close').on('video.close', function () {
            // Pause youtube
            if (!isIframe) {
              $('iframe', $module).remove();
            }
            $module.removeClass('active');
          });

          return false;
        });
      }
    },

    open: function (opts) {
      if (opts.videoProvider === 'youtube' || opts.videoProvider === 'youku') {
        this.openYouTubeOrYouku(opts);
      } else if (opts.videoProvider === 'html5') {
        this.openHTML5(opts);
      }
    },

    openYouTubeOrYouku: function (opts) {
      var youtubeOrYoukuVideoID = opts.videoProvider === 'youtube' ? opts.youTubeId : opts.youkuId;
      var content = '<div class="js-content-block-video-placeholder"></div>';
      var iframeID;

      if (opts.openOverlay) {
        /* globals generic*/
        generic.overlay.launch(videoOverlayOptions(opts));
      } else {
        opts.$content.html(content);
        iframeID = createVideoPlayerDiv($('.js-content-block-video-placeholder', opts.$content), Math.floor(+new Date() / 1000));
        if (opts.videoProvider === 'youtube') {
          addYoutubeVideo(youtubeOrYoukuVideoID, iframeID);
        } else if (opts.videoProvider === 'youku') {
          addYoukuVideo(youtubeOrYoukuVideoID, iframeID);
        }
        opts.$content.trigger('video.loaded');
      }
    },

    openHTML5: function (opts) {
      var context = !_.isEmpty(opts.context) ? opts.context : document;

      opts.$content.html(opts.content);
      if (opts.openOverlay) {
        generic.overlay.launch(videoOverlayOptions(opts));
      } else {
        opts.$content.find('video').length ? opts.$content.find('video').get(0).play() : false;
        opts.$content.trigger('video.loaded');
      }
      $(document).on('videos.pause', function () {
        $('video', context).each(function () {
          $(this).get(0).pause();
        });
      });
    }
  };

  /**
   * We initialize each type of video separately because we don't know if/when their respective libraries are loaded
   */

  $(document).on('youtubeIframeAPI.loaded', function () {
    var $elements = $('.js-content-block-video--v1').filter("[data-video-provider='youtube']");

    if ($elements.length) {
      videoBehavior.setup($elements, document);
    }
    $(document).on('videos.reattach', function (event, context) {
      var $elements = $('.js-content-block-video--v1', context);

      videoBehavior.setup($elements, document);
    });
  });

  Drupal.behaviors.contentBlockVideoV1 = {
    attach: function (context) {
      var $elementsFiltered = $('.js-content-block-video--v1', context).filter("[data-video-provider='html5'], [data-video-provider='youku'], [data-video-provider='iframe']");

      if ($elementsFiltered.length) {
        videoBehavior.setup($elementsFiltered, context);
      }
    }
  };

  function createVideoPlayerDiv($elem, i) {
    var id = $elem.attr('class') + '-' + i;

    $elem.removeClass('js-content-block-video-placeholder');
    $elem.parent().append('<div />').children('div').attr('id', id);
    $(document).on('videos.pause', function () {
      $('#' + id, document).each(function () {
        if ($(this).get(0).contentWindow) {
          $(this).get(0).contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
        }
      });
      $('video', $elem.parent()).each(function () {
        $(this).get(0).pause();
      });
    });

    return id;
  }
  function addYoukuVideo(youkuId, iframeID) {
    var youku_src = '//player.youku.com/embed/' + youkuId + '?autoplay=1';
    var ifrm = document.createElement('IFRAME');

    ifrm.setAttribute('src', youku_src);
    ifrm.setAttribute('allowfullscreen', 'allowfullscreen');
    ifrm.style.width = '100%';
    ifrm.style.border = '0';
    $('#' + iframeID).replaceWith(ifrm);
    $(document).on('videos.pause', function () {
      $('#' + iframeID).each(function () {
        if ($(this).get(0).contentWindow) {
          $(this).get(0).contentWindow.postMessage('{"event":"command","func":"' + 'pauseVideo' + '","args":""}', '*');
        }
      });
    });
  }
  function addYoutubeVideo(youtubeID, iframeID) {
    /* global YT */
    new YT.Player(iframeID, {
      height: '100%',
      width: '100%',
      videoId: youtubeID,
      playerVars: {
        autoplay: 1,
        modestbranding: 0,
        controls: 1,
        showinfo: 0,
        rel: 0
      }
    });
  }

  // eslint-disable-next-line complexity
  function resizeColorbox($cbox) {
    var aspectRatio = $(window).height() / $(window).width();
    var overlayWidth;
    var overlayHeight;
    var $video = $('video', $cbox);

    if ($video.length) {
      var videoAspectRatio;

      overlayWidth = $(window).width() <= 768 ? '100%' : $video.width();
      overlayHeight = $(window).width() <= 768 ? '100%' : $video.height();
      if (overlayWidth > 1280) {
        videoAspectRatio = $video.height() / $video.width();
        overlayWidth = 1280;
        overlayHeight = videoAspectRatio * overlayWidth;
      }
    } else {
      overlayWidth = $(window).width() <= 768 ? '100%' : .8 * $(window).width();
      if (overlayWidth > 1280) {
        overlayWidth = 1280;
      }
      overlayHeight = $(window).width() <= 768 ? '100%' : overlayWidth * aspectRatio;
    }
    $.colorbox.resize({
      width: overlayWidth,
      height: overlayHeight
    });
  }
  function videoOverlayOptions(opts) {
    return {
      content: '<div class="js-content-block-video-placeholder"></div>',
      transition: 'fade',
      speed: 0,
      overlayClose: true,
      cssStyle: {
        escKey: true,
        border: 'none',
        padding: 0,
        backgroundColor: '#000',
        height: '100%',
        width: '100%',
        position: 'fixed',
        className: 'overlay--content-block-video',
        // eslint-disable-next-line complexity
        onComplete: function () {
          var _this = this;

          _this.$cbox = $('#colorbox.overlay--content-block-video', opts.context);
          var youtubeOrYoukuVideoID = opts.videoProvider === 'youtube' ? opts.youTubeId : opts.youkuId;

          if (opts.videoProvider === 'youtube' || opts.videoProvider === 'youku') {
            // Create a new div for the player to take over inside of the colorbox
            var iframeID = createVideoPlayerDiv($('.js-content-block-video-placeholder', _this.$cbox), Math.floor(+new Date() / 1000));

            if (opts.videoProvider === 'youtube') {
              addYoutubeVideo(youtubeOrYoukuVideoID, iframeID);
            } else if (opts.videoProvider === 'youku') {
              addYoukuVideo(youtubeOrYoukuVideoID, iframeID);
            }
          } else if (opts.videoProvider === 'html5') {
            $('.js-content-block-video-placeholder', _this.$cbox).parent().html(opts.content);
            var $video = $('video', _this.$cbox);

            if ($video.length) {
              $video[0].play();
            }
          }
          $('html,body').addClass('no-scroll');
          // Animate in
          _this.$cbox.addClass('active');
          // On any resize, recalculate
          $(window).on('resize.videoOverlay', _.debounce(function () {
            $('html,body').addClass('no-scroll');
            resizeColorbox(_this.$cbox);
          }, 400));
          resizeColorbox(_this.$cbox);
          $('#cboxOverlay').on('click.colorboxClose', function () {
            $.colorbox.close();
          });
        },
        onCleanup: function () {
          var _this = this;

          _this.$cbox.removeClass('active');
          $('html,body').removeClass('no-scroll');
          $(window).off('resize.videoOverlay');
        }
      }
    };
  }
})(jQuery, Drupal);
